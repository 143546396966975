import icon1 from './icon1.svg';
import icon2 from './icon2.svg';
import icon3 from './icon3.svg';
import icon4 from './icon4.svg';
import icon5 from './icon5.svg';
import icon6 from './icon6.svg';
import icon7 from './icon7.svg';
import icon8 from './icon8.svg';
import icon9 from './icon9.svg';
import icon10 from './icon10.svg';
import icon11 from './icon11.svg';
import icon12 from './icon12.svg';
import icon13 from './icon13.svg';
import icon14 from './icon14.svg';
import icon15 from './icon15.svg';
import icon16 from './icon16.svg';
import icon17 from './icon17.svg';
import icon18 from './icon18.svg';
import icon19 from './icon19.svg';
import icon20 from './icon20.svg';
import icon21 from './icon21.svg';
import icon22 from './icon22.svg';
import icon23 from './icon23.svg';
import icon24 from './icon24.svg';

const icons = [
  { name: 'SQL', image: icon1 },
  { name: 'AWS', image: icon2 },
  { name: 'CSS3', image: icon3 },
  { name: 'Git/GitHub', image: icon4 },
  { name: 'HTML5', image: icon5 },
  { name: 'Java', image: icon6 },
  { name: 'JavaScript', image: icon7 },
  { name: 'Jenkins', image: icon8 },
  { name: 'JQuery', image: icon9 },
  { name: 'ReactJS', image: icon10 },
  { name: 'Ruby', image: icon11 },
  { name: 'SCSS', image: icon12 },
  { name: 'Circle CI', image: icon13 },
  { name: 'Cucumber', image: icon14 },
  { name: 'Python', image: icon15 },
  { name: 'Playwright', image: icon16 },
  { name: 'VueJS', image: icon17 },
  { name: 'TypeScript', image: icon18 },
  { name: 'NodeJS', image: icon19 },
  { name: 'Redux', image: icon20 },
  { name: 'Jest', image: icon21 },
  { name: 'OpenAI', image: icon22 },
  { name: 'Postman', image: icon23 },
  { name: 'Agile', image: icon24 },
];

export default icons;